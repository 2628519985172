<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title" data-aos="fade-left"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="2000" >
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-home"></i>
        </span> Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body">
            <!-- <label title="En construction" class="badge badge-primary construct" style="z-index:1"><i class="fa fa-wrench"></i></label> -->
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Nombre de commande de la semaine <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">{{purchases.length}}</h2>
            <!-- <h6 class="card-text">Increased by 60%</h6> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body">
            <!-- <label title="En construction" class="badge badge-danger construct" style="z-index:1"><i class="fa fa-wrench"></i></label> -->
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Nombre produits mis en ligne<i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5" v-if="products">{{products.length}}</h2>
            <!-- <h6 class="card-text">Decreased by 10%</h6> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body">
            <!-- <label title="En construction" class="badge badge-danger construct" style="z-index:1"><i class="fa fa-wrench"></i></label> -->
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Nombre de services offerts <i class="mdi mdi-diamond mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5" v-if="services">{{services.length}}</h2>
            <!-- <h6 class="card-text">Increased by 5%</h6> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import visitAndSalesStatitics from '../../components/charts/widgets/visitAndSalesStatitics'
// import trafficSourceChart from '../../components/charts/widgets/trafficSourceChart'
// import todoList from '../../components/apps/todoList'
// import DatePicker from 'vue2-datepicker';
// const axios= require ('axios').default;

const axios = require ('axios').default

export default {
  name: 'dashboard',
  components: {
    // DatePicker,
    // trafficSourceChart,
    // visitAndSalesStatitics,
    // todoList
  },
  mounted () {
    axios.get('purchases')
      .then(
        respurchases => {
          respurchases.data.result.forEach(element => {
            if (element.product != null ) {
              let  datepurchase= new Date(element.datepurchase)
              if ((this.getWeek(datepurchase) == this.getWeek())) {
                this.purchases.push(element) 
              }
            }
          });
          
        }
      )
      .catch(
        err => console.log({err})
      )

    axios.get('products')
      .then(
        resproducts => {
          this.products = resproducts.data.result
        }
      )
      .catch(
        err => console.log({err})
      )

    axios.get('services')
      .then(
        resservices =>  {
          this.services = resservices.data.result
        }
      )
      .catch(
        err => console.log({err})
      )
  },
  data() {
    return {
      time1: null,
      roles: null,
      purchases : [],
      products : null,
      services : null,

    };
  },
  computed:{

  },
  methods:{
    getWeek(date){
      if (date == null) {
        date = new Date()
      }
      var oneJan = new Date(date.getFullYear(),0,1);
      var numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
      var result = Math.ceil(( date.getDay() + 1 + numberOfDays) / 7);
      return result
    }
  }
}
</script>

<style scoped>
.construct{
height: 80px;
width: 80px;
border-radius: 50%;
position: absolute;
right: -32px;
top: -38px;
padding-top: 25px;
font-size: 24px;
}
</style>